.age .radio_section .radio_card  {
    justify-content: space-between !important;
}

.age .radio_section .radio_card .card_fil {
    width: 48% !important;
}

.age .radio_section .radio_card .card_fil  input {
    width: 100% !important;
    border: 1px solid rgb(0,0,0,.3);
    border-radius: 5px;
    padding: 5px 8px !important;
    margin-top: 5px !important;
}