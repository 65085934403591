.payment-failed-container {
    background-color: white;
    border: 1px solid #151313;
    border-radius: 8px;
    max-width: 553px;
    padding: 30px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 0 auto;
    margin-top: 50px;
  }
  
  .payment-failed-container h2 {
    color: #B00020;
    font-size: 20px;
    margin-bottom: 20px;
    font-weight: bold;
  }
  
  .payment-failed-container p {
    color: #555;
    font-size: 16px;
    margin-bottom: 15px;
  }
  
  .retry-button {
    background-color: #B00020;
    color: white;
    border: none;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-transform: capitalize;
    width: fit-content;
    padding: 15px;
    border-radius: 25px;
  }
  
  .retry-button:hover {
    background-color: #8b0018;
  }
  
  .icon {
    font-size: 40px;
    color: #B00020;
    margin-bottom: 20px;
  }
  
  .header {
    color: #8b0018;
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  .payment-failed-container hr {
    border: none;
    border-top: 1px solid #ddd;
    margin-bottom: 20px;
  }

  .payment-confirmation-container {
    border: 1px solid #010101;
    padding: 25px;
    background-color: #fff;
    border-radius: 8px;
    text-align: center;
    width: 100%;
    max-width: 881;
    margin: 0 auto;
    margin-top: 50px;
   
  }
  
  .header {
    color: #d22;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .confirmation-body {
    display: flex;
    justify-content: space-evenly;
    margin: 50px 0;
  }
  .confirmation-left{
    text-align: left;
    border-right: 2px solid black;
  }
  .thankucl{
    margin: 20px;
    max-width: 400px;
  }
  .confirmation-left h2 {
    color: #4CAF50;
  }
  
  .confirmation-right p {
    text-align: left;
  }
  
  .back-button {
    background-color: #B00020;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 25px;
    cursor: pointer;
    font-size: 18px;
  }
  
  .back-button:hover {
    background-color: #B00020;
  }
  
  