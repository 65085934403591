@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');


* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body {
    width: 100vw;
    /* font-family: "Roboto", sans-serif; */
    font-family: "Raleway", sans-serif;
    overflow-x: hidden;
    position: relative;
    background: #fffef8;
    scroll-behavior: smooth;
}

body::-webkit-scrollbar {
    width: 8px;
    background: var(--color-bl-2);
    border-radius: 10px;
}

body::-webkit-scrollbar-thumb {
    width: 8px;
    background: var(--color-main);
    border-radius: 10px;
}

:root {
    --color-main: #a01c22;
    --color-main-transparent: #c29c97;
    --color-wh: #fff;
    --color-wh-1: rgb(255, 255, 255, .03);
    --color-wh-1: rgb(255, 255, 255, .1);
    --color-wh-1: rgb(255, 255, 255, .2);
    --color-wh-1: rgb(255, 255, 255, .3);
    --color-wh-1: rgb(255, 255, 255, .4);
    --color-bl: #000;
    --color-bl-1: rgb(0, 0, 0, .03);
    --color-bl-2: rgb(0, 0, 0, .1);
    --color-bl-3: rgb(0, 0, 0, .2);
    --color-bl-4: rgb(0, 0, 0, .3);
    --color-bl-5: rgb(0, 0, 0, .4);

    /* font size */

    --size_head: 2rem;
}

@font-face {
    font-family: cooper-black;
    src: url('../font/Cooper-Black-Regular.ttf');
}


nav {

    width: 100%;
    margin: 0;
    position: fixed;
    padding: 0 15%;
    height: 70px;
    z-index: 9999;
    transition: .3s linear;
}

nav img {
    width: 90px;
}

nav button {
    padding: 3px 10px;
    box-shadow: 0 0 20px var(--color-bl-2);
    background: var(--color-wh) !important;
    color: var(--color-main);
    border-radius: 20px !important;
}

nav button:hover {
    background: var(--color-bl) !important;
    color: var(--color-wh);
}

nav .nav_links {
    display: flex;
    align-items: center;
    position: relative;
}

nav .nav_links a {
    color: var(--color-bl);
    padding: 3px 10px;
    margin: 0 10px;
    font-weight: 600;
    transition: .3s linear;
}

nav .nav_links a:hover {
    color: var(--color-main);
}

header {
    position: relative;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    /* overflow: hidden; */
}

header:before {
    background: url('../img/bg5.webp')no-repeat bottom/cover;
    bottom: 10vh;
    content: "";
    height: 100%;
    left: -13%;
    position: absolute;
    width: 100%;
    transform: scale(1.25);
}

/* header::before {
   position: absolute;
   content: '';
   width: 100%;
   height: 120px;
   top: 0;
   left: 0;
   background: linear-gradient(0deg , transparent , rgb(114, 114, 114));
}
header::after {
   position: absolute;
   content: '';
   width: 100%;
   height: 30vh;
   bottom: 0;
   left: 0;
   background: linear-gradient(180deg , transparent , rgb(114, 114, 114));
} */

header .content {
    width: 65%;
    height: auto;
    position: relative;
    top: -22vh;
}

/* header .content::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 20vh;
    top: 0;
    left: 0;
    background: linear-gradient(0deg , transparent , rgba(16, 16, 16, 0.2) ,  transparent );
    z-index: 0;
 } */


header .content h1 {
    font-size: 70px;
    color: var(--color-wh);
    color: var(--color-main);
    z-index: 9;
}

header .content p {
    font-size: 20px;
    color: var(--color-wh);
    color: var(--color-main);
    margin-top: 10px;
    z-index: 9;
}

header .search_bx {
    position: absolute;
    width: 65%;
    height: 150px;
    padding: 15px 40px;
    bottom: 5vh;
    z-index: 9;
}

header .search_bx::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    background: rgb(184, 184, 184, .3);
    backdrop-filter: blur(5px);
    border-radius: 10px;
    z-index: 1;
    left: 0;
    top: 0;
}


.filter_card {
    position: relative;
    z-index: 999;
    width: 26%;
}

.filter_card label {
    font-weight: 500;
    color: var(--color-wh);
}

.filter_card input {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    border-radius: 5px;
    outline: none;
    border: none;
}

.filter_card i {
    position: absolute;
    top: 38px;
    right: 15px;
    transition: .3s linear;
}

.filter_card i:nth-child(3) {
    position: absolute;
    top: 1px;
    right: -18px;
    cursor: pointer;
    font-size: 18px;
    cursor: pointer;
    display: none;
}

.filter_card:hover i {
    transform: rotate(180deg);
}

.filter_card .entry_search input {
    border-radius: 15px;
    padding: 6px 15px;
    margin-right: 5px;
    background: #fff;
    border: 2px solid rgb(0, 0, 0, .07);
    outline: none;
}

.filter_card .entry_search input:focus {
    border: 2px solid var(--main_color);
}

.filter_card button {
    background: var(--color-wh);
    color: var(--color-bl);
    padding: 8px 15px;
    position: relative;
    top: 15px;
}

.filter_card button:hover {
    background: var(--color-bl);
    color: var(--color-wh);
}

.drop_bx {
    position: absolute;
    padding: 0 20px;
    top: 100%;
    left: 0;
    width: 100%;
    height: 00px;
    min-height: 0;
    background: #fff;
    box-shadow: 0 0 20px rgb(0, 0, 0, .05);
    border-radius: 0 0 10px 10px;
    overflow-y: auto;
    z-index: 999;
    transition: .3s linear;
}

.drop_bx p {
    font-size: 12px;
    color: gray;
    text-align: center;
    font-weight: 600;
}

/* Webkit browsers (Chrome, Edge, Safari) */
.drop_bx::-webkit-scrollbar {
    width: 5px;
    background: var(--color-bl-2);
    border-radius: 10px;
}

.drop_bx::-webkit-scrollbar-thumb {
    background: var(--color-main);
    border-radius: 10px;
}

/* Firefox */
.drop_bx {
    scrollbar-width: thin;
    /* Set scrollbar width to thin */
    scrollbar-color: var(--color-main) var(--color-bl-2);
    /* Thumb and track color */
}

/* For Internet Explorer */
.drop_bx {
    -ms-overflow-style: -ms-autohiding-scrollbar;
    /* Optional, hides scrollbar until needed */
}


.drop_bx_active {
    height: auto;
    max-height: 180px;
    padding: 10px 20px;
    z-index: 99999;
}

.drop_bx li {
    margin-top: 10px;
    padding: 5px 10px;
    font-size: 13px;
    font-weight: 600;
    cursor: pointer;
    transition: .3s linear;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.drop_bx li:hover {
    background: var(--color-main);
    color: #fff;
    border-radius: 8px;
}


.first_step .filter_card {
    width: 90%;
    margin-top: 15px;
    margin-left: 40px;
}

.first_step .filterIncome {
    margin-left: 0;
}

.first_step .filter_card label {
    color: var(--color-bl);
    font-weight: 600;
    font-size: 15px;
}

.first_step .filter_card label i {
    position: unset;
    top: unset;
    margin-right: 5px;
}

.first_step .filter_card input,
textarea {
    padding: 13px 15px;
    border-radius: 10px;
    border: 1px solid rgb(165, 165, 165);
}

.first_step textarea {
    margin-top: 10px;
}

.first_step .drop_bx {
    width: 100%;
    box-shadow: 0 0 20px rgb(0, 0, 0, .1);
}

.first_step_drop {
    position: relative;
    top: 20px;
}

.first_step_drop .card_bx {
    margin-left: 10%;
}

.pp .card_bx:nth-child(2) {
    display: flex;
    align-items: center;
    justify-content: center;
}

.pp .card_bx:nth-child(2) .profile_signUp {
    position: relative;
    width: 100px;
    height: 100px;
    margin-top: 15px;
}

.pp .card_bx .profile_signUp input {
    width: 100px;
    height: 100px;
    opacity: 0;
}

.pp .card_bx .profile_signUp img {
    width: 100px;
    height: 100px;
    position: absolute;
    left: 0;
    border-radius: 10px;
    border: 2px solid gainsboro;
    z-index: 0;
}

/* why  */

.why {
    margin: -60px auto;
}

.why .why_cards {
    margin-top: 40px;
}

.why .why_cards .why_card {
    width: 32%;
    padding: 0 20px;
}

.why .why_cards .why_card:nth-child(2) {
    border: 2px solid var(--color-bl-1);
    border-top: none;
    border-bottom: none;
}

.why .why_cards .why_card .img_bx {
    width: 150px;
    height: 150px;
}

.why .why_cards .why_card .img_bx img {
    width: 150px;
    mix-blend-mode: darken;
}

/* Register Band  */

.register_band {
    background: var(--color-main);
    color: var(--color-wh);
    border-radius: 15px;
    height: 120px;
    margin: 60px auto;
    margin-top: 100px;
    overflow: hidden;
    padding: 0 50px 0 80px;
    position: relative;
    box-shadow: 0 0 20px rgb(0, 0, 0, .07);
}

.register_band::before {
    position: absolute;
    content: '';
    width: 100px;
    height: 100%;
    left: -50px;
    top: 0;
    background: url('../img/royal_design.png')no-repeat center center/cover;
    z-index: 999;
}


.register_band2::before {
    position: absolute;
    content: '';
    width: 100px;
    height: 100%;
    left: unset;
    right: -50px;
    top: 0;
    background: url('../img/royal_design.png')no-repeat center center/cover;
    z-index: 999;
}

.register_band2 {
    padding: 0 13%;
    padding-top: 30px;
}

.register_band2 h1:nth-child(2) {
    text-align: right;
    margin-top: 8px;
}

.register_band h1 {
    font-size: 20px;
    color: var(--color-wh);
}

.register_band button {
    background: var(--color-wh);
    color: var(--color-main);
    box-shadow: 0 0 20px rgba(250, 227, 159, 0.07);
    padding: 10px 20px;
    font-size: 17px;
    border-radius: 10px;
}

.register_band button:hover {
    background: var(--color-wh);
    color: var(--color-bl);
}

/* Footer  */

footer {
    background: var(--color-main);
    padding: 40px 0;
}

footer .foot_bx {
    width: 19.5%;
    height: 300px;
    padding: 0 10px;
}

footer .foot_bx:nth-child(1) {
    width: 22%;
    height: 300px;
    padding: 0 10px;
}

footer .foot_bx:nth-last-child(1) {
    width: 17%;
    height: 300px;
    padding: 0 10px;
}

footer .foot_bx p {
    font-size: 13px;
    margin-top: 15px;
    color: var(--color-wh);
    line-height: 20px;
    word-break: break-all;
}

footer .foot_bx h5 {
    color: var(--color-wh);
    font-size: 16px;
    margin-top: 20px;
}

footer .foot_bx ul {
    margin-top: 25px;
}

footer .foot_bx ul li {
    list-style: none;
    padding: 7px;
}

footer .foot_bx ul li a {
    text-decoration: none;
    color: var(--color-wh);
    font-size: 13px;
    font-weight: 500;
    display: flex;
    transition: .3s linear;
}

footer .foot_bx ul li a:hover {
    color: var(--color-wh-4);
}

footer .foot_bx .social_media {
    margin-top: 25px;
}

footer .foot_bx .social_media a {
    color: var(--color-wh);
    transition: .3s linear;
    margin-right: 35px;
    position: relative;
}

footer .foot_bx .social_media a i {
    position: absolute;
    transition: .3s linear;
}

footer .foot_bx .social_media a:hover i {
    transform: rotate(360deg);
}

footer .foot_bx ul li a i {
    margin-right: 5px;
}

footer h6 {
    position: relative;
    color: var(--color-wh);
    font-size: 10px;
    letter-spacing: 1px;
}

footer h6::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 1px;
    background: rgba(255, 252, 252, .2);
    top: -50px;
    left: 0;
}

.signup_process {
    margin-top: 40px !important;
}

.signup_process .why_card {
    border: none !important;
}

.signup_process h1 span {
    color: var(--color-main);
}

.signup_process p span {
    color: var(--color-main);
}

.signup_process .why_card h5 {
    color: var(--color-main);
    font-size: 18px;
    padding-bottom: 15px;
}


.nri_tags {
    margin: 30px auto;
    margin-bottom: 90px;
}

.nri_tags ul li,
a {
    color: gray;
    list-style: none;
    font-size: 14px;
}

.nri_tags ul h5 {
    width: 2px;
    height: 15px;
    background: rgb(185, 185, 185);
}

.nri_tags_first {
    padding: 20px 0;
}


.nri_tags_first h4 {
    color: gray;
    padding: 15px 0;
    font-size: 14px;
    letter-spacing: 1px;
}

.nri_tags_first ul {
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
}

.nri_tags_first ul li {
    padding: 10px;
}

.head span {
    color: var(--color-main);
}

.spiritual {
    background: url('../img/4_line_img.png')no-repeat center right/cover;
    height: 350px;
}

.spiritual h4 {
    display: table;
    color: var(--color-wh);
    padding: 8px 10px;
    border-radius: 8px;
    margin-top: 20px;
    font-weight: 800;
    font-size: 19px;
}

.spiritual h4:nth-child(odd) {
    background: var(--color-main);
    color: var(--color-wh);
    padding: 8px 10px;
    border-radius: 8px;
    margin-top: 20px;
    font-weight: 800;
    font-size: 19px;
}


.spiritual img {
    mix-blend-mode: hard-light;
    width: 310px;
}

.nri_rishtey {
    height: 220px;
    background: var(--color-main);
    border-radius: 10px;
    box-shadow: 0 0 20px var(--color-bl-1);
    margin: 80px auto;
    margin-bottom: 35px;
    padding: 20px 30px;
    flex-wrap: wrap;
}

.nri_rishtey .left_bx_ad,
.right_bx_ad {
    width: 35%;
}

.nri_rishtey .left_bx_ad {
    width: 65%;
    height: 60%;
    border-right: 2px solid var(--color-bl);
    display: flex;
    align-items: center;
    justify-content: center;
}

.nri_rishtey .left_bx_ad h1 {
    color: var(--color-wh);
    font-weight: 400;
    font-size: 30px;
}

.nri_rishtey .right_bx_ad h1 {
    color: rgb(255, 207, 104);
}

.nri_rishtey .right_bx_ad h1 {
    line-height: 35px;
}

.nri_rishtey .right_bx_ad button {
    background: var(--color-wh);
    padding: 6px 10px;
    margin-top: 10px;
    border-radius: 10px;
}

.nri_rishtey .tags p {
    color: var(--color-wh);
    font-size: 12px;
    margin-top: 20px;
    padding: 3px 8px;
    border-right: 1px solid var(--color-wh);
}

.nri_rishtey .tags p:nth-last-child(1) {
    border-right: none;
}


/* login popup start */

.container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    width: 100vw;
    /* filter: hue-rotate(110deg); */
    position: fixed;
    top: 0;
    left: 0;
    z-index: -99999;
    opacity: 0;
    background: var(--color-bl-5);
    transition: .3s linear;
}

.container_active {
    z-index: 99999999999;
    opacity: 1;
}

.screen {
    background: var(--color-wh);
    position: relative;
    height: 600px;
    width: 360px;
    /* box-shadow: 0px 0px 24px #5C5696; */
    border-radius: 15px;
    overflow: hidden;
    border: 8px solid var(--color-main);
    padding: 35px;
}

.screen__content {
    z-index: 1;
    position: relative;
    height: 100%;
}

.screen__background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    -webkit-clip-path: inset(0 0 0 0);
    clip-path: inset(0 0 0 0);
}

.screen__background__shape {
    transform: rotate(45deg);
    position: absolute;
}

.screen__background__shape1 {
    height: 520px;
    width: 520px;
    background: #FFF;
    top: -50px;
    right: 120px;
    border-radius: 0 72px 0 0;
}

.screen__background__shape2 {
    height: 220px;
    width: 220px;
    background: #6C63AC;
    top: -172px;
    right: 0;
    border-radius: 32px;
}

.screen__background__shape3 {
    height: 540px;
    width: 190px;
    background: linear-gradient(270deg, #5D54A4, #6A679E);
    top: -24px;
    right: 0;
    border-radius: 32px;
}

.screen__background__shape4 {
    height: 400px;
    width: 200px;
    background: #7E7BB9;
    top: 420px;
    right: 50px;
    border-radius: 60px;
}

.login {
    width: 100%;
}

.login h1 {
    font-size: 25px;
}

.login p {
    margin-top: 5px;
    font-size: 15px;
}

.login label {
    font-weight: 600;
    font-size: 15px;
    padding-left: 5px;
}

.login__field {
    padding: 10px 0px;
    position: relative;
}

.login__field:nth-child(1) {
    padding: 20px 0px;
}

.login__field_first {
    margin-top: 30px;
}


.login__field .pass_open {
    position: absolute;
    right: 10px;
    bottom: 20px;
    cursor: pointer;
}

.login__icon {
    position: absolute;
    top: 30px;
    color: #7875B5;
}

.register_now_sec {
    width: 100%;
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.register_now_sec p {
    font-size: 12px;
    font-weight: 600;
}

.register_now_sec button {
    border: 1px solid var(--color-main);
    padding: 7px 28px;
    background: var(--color-main);
    border-radius: 20px;
    color: var(--color-wh);
    cursor: pointer;
    margin-top: 10px;
    transition: .3s linear;
}

.login_exit {
    position: absolute;
    top: -25px;
    right: -20px;
    cursor: pointer;
    transition: .3s linear;
}

.login_exit:hover {
    color: var(--color-main);
}

.register_now_sec button:hover {
    background: transparent;
    color: var(--color-main);
    outline: none;
}

.login__input {
    background: #d1d1d46e;
    padding: 12px 15px;
    border-radius: 15px;
    margin-top: 6px;
    width: 100%;
    border: none;
}

.login__input::placeholder {
    font-size: 13px;
}

.login__input:active,
.login__input:focus,
.login__input:hover {
    outline: none;
    border-bottom-color: #6A679E;
}

.login__submit {
    border: 1px solid var(--color-main);
    padding: 7px 28px;
    background: var(--color-main);
    border-radius: 20px;
    color: var(--color-wh);
    cursor: pointer;
    margin-top: 10px;
    transition: .3s linear;
}

.login__submit:active,
.login__submit:focus,
.login__submit:hover {
    background: transparent;
    color: var(--color-main);
    outline: none;
}

.login_button_forg {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.forgot_link {
    margin-top: 13px;
    font-size: 14px;
    color: var(--color-bl);
}


input:-internal-autofill-selected {
    appearance: menulist-button;
    background-image: none !important;
    background-color: var(--color-wh) !important;
    color: fieldtext !important;
}

.social-login {
    position: absolute;
    height: 140px;
    width: 160px;
    text-align: center;
    bottom: 0px;
    right: 0px;
    color: #fff;
}

.social-login a {
    color: var(--color-wh);
}

.social-icons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
}

.social-login__icon {
    padding: 20px 10px;
    color: #fff;
    text-decoration: none;
    text-shadow: 0px 0px 8px #7875B5;
}

.social-login__icon:hover {
    transform: scale(1.5);
}

.social-icons i {
    position: absolute;
    cursor: pointer;
    transition: .3s linear;
}

.social-icons i:hover {
    color: var(--color-main);
    transform: rotate(360deg);
    filter: hue-rotate(255deg);
}

/* login popup end */


/* signup start  */

.sign_up_steps {
    position: fixed;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: rgb(0, 0, 0, .3);
    z-index: -9999;
    opacity: 0;
}

.sign_up_steps .signn_up {
    width: 420px;
    height: auto;
    min-height: 200px;
    max-height: auto;
    padding: 30px;
    background: #fff;
    border-radius: 20px;
    box-shadow: 0 0 20px rgb(0, 0, 0, .03);
    position: relative;
}

.sign_up_steps .sign_step_one {
    width: 100%;
}

.sign_up_steps .sign_step_one .steps {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.sign_up_steps .sign_step_one .steps .step_card {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 12.5%;
    position: relative;
}

.sign_up_steps .sign_step_one .steps .step_card::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 3px;
    background: var(--color-bl-1);
    top: 17.2px;
}

.sign_up_steps .sign_step_one .steps .step_card h6 {
    width: 35px;
    height: 35px;
    font-size: 14px;
    border-radius: 50%;
    border: 2px solid var(--color-bl-1);
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--color-bl-2);
    color: var(--color-bl);
    position: relative;
}

.sign_up_steps .sign_step_one .steps .step_card span {
    font-size: 12px;
    font-weight: 600;
    margin-top: 10px;
}

.sign_up_steps .sign_step_one .steps .step_card:nth-child(1):before {
    width: 50%;
    left: unset;
    right: 0;
}

.sign_up_steps .sign_step_one .steps .step_card:nth-last-child(1):before {
    width: 50%;
    left: 0;
    right: unset;
}

.sign_up_steps .sign_step_one .steps .step_card_active:before {
    background: var(--color-main);
    color: var(--color-wh);
}

.sign_up_steps .sign_step_one .steps .step_card_active h6 {
    background: var(--color-main);
    color: var(--color-wh);
}

.sign_up_steps .signn_up .one_sign_up {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    /* flex-wrap: wrap; */
    overflow-x: hidden;
    position: relative;
    scroll-behavior: smooth;
}

.sign_up_steps .signn_up .one_sign_up::-webkit-scrollbar {
    display: none;
}

.sign_up_steps .signn_up .one_sign_up .first_step {
    min-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.signn_up .bi-arrow-left {
    position: absolute;
    top: 20px;
    left: 20px;
    font-size: 16px;
    cursor: pointer;
    z-index: 999;
    transition: .3s linear;
}

.signn_up .bi-arrow-left:hover {
    color: var(--color-main);
    transform: rotate(360deg);
}

.signn_up .bi-x-circle-fill {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 16px;
    cursor: pointer;
    z-index: 999;
    transition: .3s linear;
}

.signn_up .bi-x-circle-fill:hover {
    color: var(--color-main);
    transform: rotate(360deg);
}

.sign_up_steps .signn_up .one_sign_up .first_step {
    position: relative;
}

.sign_up_steps .signn_up .one_sign_up .first_step .card_bx {
    position: relative;
    display: flex;
    min-width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
}

.sign_up_steps .signn_up .one_sign_up .first_step .card_bx .deye {
    right: 30px;
    top: 60px;
    position: absolute;
    z-index: 999;
    cursor: pointer;
}

.sign_up_steps .signn_up .one_sign_up .first_step .card_bx label {
    margin-top: 20px;
    font-size: 15px;
    font-weight: 600;
    width: 90%;
}

.sign_up_steps .signn_up .one_sign_up .first_step .card_bx label i {
    margin-right: 8px;
}

.sign_up_steps .signn_up .one_sign_up .first_step .card_bx label span {
    margin-left: 10px;
    font-size: 11px;
    cursor: pointer;
    color: var(--color-main);
}

.sign_up_steps .signn_up .one_sign_up .first_step .card_bx input {
    width: 90%;
    margin-top: 10px;
    padding: 13px 15px;
    border-radius: 10px;
    border: 1px solid rgb(165, 165, 165);
    outline: none;
    position: relative;
    z-index: 99;
    cursor: pointer;
}

.sign_up_steps .signn_up .one_sign_up .first_step .card_bx:nth-last-child(1) {
    justify-content: center;
    align-items: center;
    width: 100%;
}

.sign_up_steps .signn_up .one_sign_up .first_step .card_bx:nth-child(1) {
    justify-content: center;
    align-items: center;
    width: 100%;
}


.sign_up_steps .signn_up .one_sign_up .first_step .card_bx:nth-child(1) .icon_fade .bi-x-circle-fill {
    position: absolute;
    top: -10px;
    right: -10px;
    cursor: pointer;
    transition: .3s linear;
}

.sign_up_steps .signn_up .one_sign_up .first_step .card_bx:nth-child(1) .icon_fade .bi-x-circle-fill:hover {
    color: var(--color-main);
}

.sign_up_steps .signn_up .one_sign_up .first_step .card_bx:nth-child(1) i {
    position: absolute;
    z-index: 9;
    color: var(--color-wh);
    font-size: 22px;
}

.sign_up_steps .signn_up .one_sign_up .first_step .card_bx:nth-child(1) .icon_fade {
    display: box;
    position: relative;
    width: 70px;
    height: 70px;
    background: linear-gradient(to bottom, #ffee96 0%, #701418 100%);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    line-height: 100px;
    text-align: center;
    text-decoration: none;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sign_up_steps .signn_up .one_sign_up .first_step .card_bx:nth-child(1) .icon_fade::before {
    display: block;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: rgb(252, 129, 129);
    opacity: 0;
    content: "";
    animation: pulse 2s linear infinite;
}

.sign_up_steps .signn_up .one_sign_up .first_step .card_bx:nth-child(1) .icon_fade:hover::before {
    animation: none;
    opacity: 0.4;
    transform: scale(1.3);
}


.sign_up_steps .signn_up .one_sign_up .first_step .card_bx:nth-child(1) .icon_fade {
    animation: blastOut 1s;
}

@keyframes pulse {
    0% {
        transform: scale(1);
        opacity: 0.4;
        z-index: 0;
    }

    50% {
        transform: scale(1.3);
        opacity: 0;
    }

    100% {
        transform: scale(1);
        opacity: 0;
    }
}

@keyframes blastOut {
    from {
        transform: scale(0.9);
        opacity: 0.4;
    }

    to {
        transform: scale(1.5);
        opacity: 0;
    }
}


.sign_up_steps .signn_up .one_sign_up .first_step .card_bx button {
    margin-top: 20px;
    padding: 13px 10px;
    width: 100px;
    border-radius: 10px;
    background: var(--color-main);
    color: #fff;
    border: 2px solid var(--color-main);
    outline: none;
    cursor: pointer;
    transition: .3s linear;
}

.sign_up_steps .signn_up .one_sign_up .first_step .card_bx button:hover {
    background: transparent;
    color: var(--color-main);
    font-weight: 600;
}



.sign_up_steps .one ul {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 20px;
    width: 350px;
}

.sign_up_steps .one ul li {
    width: 130px;
    list-style: none;
    border: 1px solid gainsboro;
    padding: 10px 12px;
    border-radius: 10px;
    margin: 5px 5px;
    cursor: pointer;
    font-weight: 600;
    text-wrap: nowrap;
    font-size: 13px;
    text-align: center;
    position: relative;
    transition: .3s linear;
}

.sign_up_steps .one ul li i {
    display: none;
    transition: .3s linear;
    opacity: 0;
}

.sign_up_steps .one ul li:hover {
    background: var(--color-main);
    color: var(--color-wh);
}

.activeProfile {
    background: var(--color-main);
    color: var(--color-wh);
}

.activeProfile i {
    display: unset;
    margin-right: 5px;
    opacity: 1;
}

.sign_up_steps .one ul li:hover i {
    display: unset;
    margin-right: 5px;
    opacity: 1;
}

.sign_up_steps .one h3 {
    text-align: center;
    margin-top: 15px;
}

/* signup end  */


section {
    position: relative;
    top: 0px;
    padding-top: 105px;
    padding-bottom: 40px;
    min-height: 300px;
}

section .dash_cards {
    flex-wrap: wrap;
}

section .dash_cards .dash_card {
    width: 31%;
    height: 70px;
    background: var(--color-wh);
    border-radius: 10px;
    box-shadow: 0 0 20px var(--color-bl-3);
    padding: 10px 20px;
    margin: 1%;
}

section .dash_cards .dash_card i {
    background: yellowgreen;
    padding: 10px 12px;
    border-radius: 50%;
    box-shadow: 0 0 20px #aafb06;
    color: #fff;
    font-size: 16px !important;
}

section .dash_cards .dash_card:nth-child(2) i {
    font-size: 30px;
    background: rgb(50, 174, 205);
    box-shadow: 0 0 20px rgb(50, 174, 205);
}

section .dash_cards .dash_card:nth-child(3) i {
    font-size: 30px;
    background: rgb(190, 50, 205);
    box-shadow: 0 0 20px rgb(190, 50, 205);
}

section .dash_cards .dash_card:nth-child(4) i {
    font-size: 30px;
    background: rgb(205, 94, 50);
    box-shadow: 0 0 20px rgb(205, 94, 50);
}

section .dash_cards .dash_card:nth-child(5) i {
    font-size: 30px;
    background: rgb(205, 197, 50);
    box-shadow: 0 0 20px rgb(205, 197, 50);
}

section .dash_cards .dash_card:nth-child(6) i {
    font-size: 30px;
    background: rgb(120, 50, 205);
    box-shadow: 0 0 20px rgb(120, 50, 205);
}

section .dash_cards .dash_card .content h3 {
    font-size: 23px;
    font-family: "Roboto", sans-serif;
}

section .dash_cards .dash_card .content p {
    margin-top: 5px;
    font-size: 12px;
}


section .profile_recent_etc {
    margin-top: 20px;
    flex-wrap: wrap;
    align-items: flex-start;
}

section .profile_recent_etc .cards_recent_added {
    width: calc(100% - 290px);
}

section .profile_recent_etc .visiter_profile {
    width: 260px;
    height: auto;
}

section .profile_recent_etc .profile {
    width: 100%;
    background: var(--color-wh);
    border-radius: 10px;
    box-shadow: 0 0 20px var(--color-bl-3);
    padding: 10px 20px;
    margin: 10px;
}

section .profile_recent_etc .profile:nth-child(2) {
    position: relative;
}


section .profile_recent_etc .profile li {
    padding: 10px 5px;
    list-style: none;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'Poppins', sans-serif;

    margin-top: 5px;
}

section .profile_recent_etc .profile li:nth-child(2) {
    margin-top: 10px;
}

section .profile_recent_etc .profile li:nth-child(3) {
    border-top: 1px solid gainsboro;
    border-bottom: 1px solid gainsboro;
}

section .profile_recent_etc .profile li .det {
    width: 100%;
}

section .profile_recent_etc .profile li .det h6 {
    font-size: 12px;
}

section .profile_recent_etc .profile li .det p {
    font-size: 13px;
    margin-top: 3px;
}

section .profile_recent_etc .profile li button {
    padding: 4px 10px;
    background: var(--color-main);
    border: 1px solid var(--color-main);
    outline: none;
    cursor: pointer;
    color: #fff;
    border-radius: 11px;
    font-size: 11px;
    transition: .3s linear;
}

section .profile_recent_etc .profile li button:hover {
    background: transparent;
    color: var(--color-main);
}

section .profile_recent_etc .profile li:nth-child(4) button {
    padding: 5px 11px;
    background: transparent;
    color: var(--color-main);
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    font-size: 11px;
    margin-top: 5px;
}

section .profile_recent_etc .profile li:nth-child(4) button:hover {
    background: var(--color-main);
    color: var(--color-wh);
}

section .profile_recent_etc .recent {
    position: relative;
    width: 100%;
    max-width: 100%;
    height: 334px;
    /* background: var(--color-wh); */
    border-radius: 10px;
    /* box-shadow: 0 0 20px var(--color-bl-3); */
    padding: 10px 20px;
    margin: -7px 5px;
    scroll-behavior: smooth;
}

section .profile_recent_etc .recent .recent_cards {
    width: 100%;
    height: 240px;
    overflow-x: auto;
    margin-top: 12px;
    scroll-behavior: smooth;
    justify-content: unset;
}

section .profile_recent_etc .recent .recent_cards::-webkit-scrollbar {
    display: none;
}

section .profile_pri {
    width: 100%;
    height: 340px;
    /* background: var(--color-wh); */
    border-radius: 10px;
    /* box-shadow: 0 0 20px var(--color-bl-3); */
    padding: 10px 20px;
    margin-top: 20px;
}


section .card-container {
    background-color: #a01c22d9;
    border-radius: 5px;
    color: #B3B8CD;
    padding-top: 15px;
    position: relative;
    min-width: 180px;
    height: 230px;
    max-width: 100%;
    text-align: center;
    margin-right: 15px;
    box-shadow: 0 0 20px rgb(0, 0, 0, .05);
}

section .card-container i {
    position: absolute;
    top: 10px;
    right: 0;
    font-size: 18px !important;
}

section .card-container .pro {
    color: #231E39;
    background-color: #FEBB0B;
    border-radius: 3px;
    font-size: 13px;
    font-weight: bold;
    padding: 3px 7px;
    position: absolute;
    top: 10px;
    left: 10px;
}

section .card-container .round {
    border: 1px solid var(--color-wh);
    border-radius: 50%;
    padding: 7px;
    width: 100px;
    height: 100px;
}

section .recent h3 {
    margin: 10px 0;
    color: var(--color-wh);
}

section .recent h6 {
    margin: 5px 0;
    text-transform: uppercase;
    color: var(--color-wh);
}

section .recent p {
    font-size: 12px;
    line-height: 15px;
    padding-top: 5px;
    color: var(--color-wh-4);
}

section .recent .recent_buttons {
    margin-top: 15px;
}

section .recent_buttons i {
    color: #fff;
    font-size: 23px;
    margin: 0 10px;
    cursor: pointer;
    transition: .3s linear;
}

section .recent_buttons i:hover {
    color: gray;
}

section .recent .head h3 {
    font-size: 16px;
    color: var(--color-main);
    font-weight: 800;
}

section .recent .head .scroll_btn i {
    font-size: 20px;
}


section .profile_pri .recent {
    width: 100%;
    max-width: 100%;
    height: 380px;
}

section .profile_pri .recent .recent_cards {
    overflow-x: auto;
    height: 280px;
    margin-top: 10px;
}

section .profile_pri .recent .recent_cards::-webkit-scrollbar {
    display: none;
}

section .profile_recent_etc .profile {
    flex-direction: column;
    justify-content: flex-start;
    padding: 20px 15px;
}

section .profile_recent_etc .profile .edit_img {
    position: relative;
}

section .profile_recent_etc .profile .edit_img img {
    border-radius: 10px;
    width: 155px;
    height: 150px;
}

section .profile_recent_etc .profile .edit_img i {
    position: absolute;
    width: 85px;
    height: 85px;
    opacity: 0;
}

section .profile_recent_etc .profile .edit_img input {
    position: absolute;
    width: 85px;
    height: 85px;
    opacity: 0;
}

section .ad_p img {
    padding: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

section .primumium_scetion::before {
    content: '';
    position: absolute;
    width: calc(100% - 200px);
    height: 85%;
    bottom: 0;
    right: 0;
    border-radius: 10px;
    background: rgb(184, 184, 184, .1);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    z-index: 999;
}


.myphoto {
    min-height: 200px;
    display: flex;
    justify-content: space-between;
    padding-top: 125px !important;

}


.myphoto .left_bx,
.right_bx {
    position: relative;
    width: calc(100% - 200px);
}

.myphoto .left_bx {
    width: 200px;
    margin-right: 20px;
    height: fit-content;
    padding: 20px;
    background: #fff;
    box-shadow: 0 0 20px rgb(0, 0, 0, .1);
    border-radius: 10px;
    margin-top: 70px;
}

.myphoto .left_bx .links_myphoto {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 15px;
}

.myphoto .left_bx .links_myphoto a {
    padding: 5px 0;
    margin-top: 5px;
    color: rgb(31, 31, 31);
    font-weight: 500;
    font-family: 'poppins', sans-serif;
    font-size: 12px;
    transition: .3s linear;
}

.myphoto .left_bx .links_myphoto a:hover {
    color: var(--color-main);
    font-size: 13px;
}

.myphoto .right_bx {
    background: var(--color-wh);
    padding: 20px;
    box-shadow: 0 0 20px rgb(0, 0, 0, .1);
    border-radius: 10px;
}

.myphoto .right_bx h1 {
    color: var(--color-main);
}

.myphoto .right_bx .upload_photo {
    width: 70%;
    height: 150px;
    border-radius: 10px;
    background: #f6cfd1ba;
    margin: 40px auto 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
}

.myphoto .right_bx .upload_photo input {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    z-index: 999;
    opacity: 0;
    cursor: pointer;
}

.myphoto .right_bx .upload_photo button {
    padding: 8px 20px;
    background: var(--color-main);
    color: var(--color-wh);
    border: none;
    outline: none;
    margin-top: 15px;
    border-radius: 5px;
    transition: .3s linear;
}

.myphoto .right_bx .upload_photo:hover button {
    background: unset;
    color: var(--color-main);
    border: 2px solid var(--color-main);
}

.myphoto .right_bx .upload_photo p {
    text-align: center;
    width: 70%;
    font-size: 16px;
    color: var(--color-bl);
}

.myphoto .right_bx .pra {
    text-align: center;
    width: 70%;
    font-size: 16px;
    color: var(--color-bl);
    margin: 20px auto;
}

.myphoto .recent img {
    width: 90%;
    height: 90%;
    border-radius: 10px;
    border: 3px solid var(--color-main);
    box-shadow: 0 0 20px rgb(0, 0, 0, .1);
}

.myphoto .recent .card-container {
    background: unset !important;
    box-shadow: unset;
    margin-right: 0;
    min-width: 150px;
    height: 180px;
}

.myphoto .recent .recent_cards {
    overflow-x: auto;
}

.myphoto .recent .recent_cards::-webkit-scrollbar {
    display: none;
}

.myphoto .radio_btns {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.myphoto .radio_btns h4 {
    margin-top: 10px;
}

.myphoto .radio_btns .card {
    margin-top: 8px;
}

.myphoto .radio_btns .card label {
    margin-left: 8px;
    font-size: 14px;
}

.myphoto .radio_btns .card input {
    accent-color: var(--color-main);
}

.myphoto .pra2 {
    font-size: 13px;
    color: gray;
    margin: 20px 0;
}

.myphoto .pra2 a {
    color: var(--color-main);
}


/* Nav Then Login Start  */

nav .dropdown_nav {
    display: none;
}



.active_nav {
    background: #a01c22;
    width: 100%;
    margin: 0;
    padding: 0 15%;
    height: 70px;
    box-shadow: 0 8px 20px rgb(0, 0, 0, .3);
    z-index: 9999999999999;
}

.active_nav::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    height: 40px;
    width: 100%;
    background: var(--color-wh);
    z-index: 9;
}

.active_nav .nav_links a {
    color: var(--color-wh);
}

.active_nav .nav_links a:hover {
    color: var(--color-wh-4);
}

/* Nav Then Login End */

.active_nav .dropdown_nav {
    position: absolute;
    top: 210%;
    left: 0;
    display: flex;
    padding: 10px 20px;
    align-items: center;
    background: var(--color-wh);
    z-index: 99999;
}

.active_nav .dropdown_nav::before {
    content: '';
    position: absolute;
    top: -10px;
    left: 50px;
    background: var(--color-wh);
    width: 20px;
    height: 20px;
    z-index: 9999;
    transform: rotate(45deg);
}

.active_nav .dropdown_nav_dash::before {
    left: 50px;
}

.active_nav .dropdown_nav_mach::before {
    left: 150px;
}

.active_nav .dropdown_nav_seac::before {
    left: 240px;
}

.active_nav .dropdown_nav_inbox::before {
    left: 325px;
}

.active_nav .dropdown_nav li {
    text-wrap: nowrap;
    list-style: none;
}

.active_nav .dropdown_nav li a {
    color: var(--color-bl);
    position: relative;
    transition: .3s linear;
}

.active_nav .dropdown_nav li a:hover {
    color: var(--color-main);
}

.active_nav .dropdown_nav li a:hover {
    color: var(--color-main);
}

.active_nav1 .dropdown_nav {
    display: none;
}

.active_nav1::before {
    background: transparent;
}

.partner_pre .left_right_bx {
    border: 2px solid #000;
    margin-top: 30px;
    align-items: flex-start !important;
}

.partner_pre .left_bx_pre,
.right_bx_pre {
    width: 50%;
    padding: 20px 60px;
    position: relative;
    font-family: 'Poppins', sans-serif;
}

.partner_pre .left_bx_pre::before {
    position: absolute;
    content: '';
    width: 2px;
    border-radius: 10px;
    height: 60%;
    top: 30%;
    right: 0;
    background: var(--color-bl);
}

.partner_pre h3 {
    text-align: center;
    margin: 25px 0;
    color: var(--color-main);
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 40px;
}

.partner_pre .card {
    display: flex;
    margin-top: 20px;
}

.partner_pre .card label {
    color: rgb(138, 138, 138);
    font-weight: 500;
    margin-right: 15px;
    width: 150px;
    font-size: 14px;
}

.partner_pre .card .content li {
    color: rgb(4, 88, 88);
    list-style: none;
    font-size: 13px;
    margin-top: 5px;
}

/* Setting  */
.setting {
    position: relative;
}

.setting .setting_bx {
    width: 100%;
    box-shadow: 0 0 20px rgb(0, 0, 0, .1);
    margin-top: 20px;
    padding: 20px;
    border-radius: 8px;
}


.setting .setting_contact {
    flex-direction: column;
    align-items: flex-start;
}

.setting .setting_contact h6 {
    all: unset;
    margin-top: 10px !important;
    font-size: 17px !important;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
}

.setting .setting_contact h6 sup {
    margin-left: 5px;
}

.setting .setting_contact h6 a {
    transition: .3s linear;
    font-size: 17px !important;
    margin-left: 5px;

}

.setting .setting_contact h6 a:hover {
    color: var(--color-main);
    font-weight: 600;
}

.setting .setting_filter .sub_filters {
    padding: 30px;
    padding-top: 20px;
}

.setting .setting_filter .sub_filters h5 {
    margin-top: 10px;
    font-size: 18px;
    color: var(--color-wh-5);
    font-weight: 600;
}

.setting .setting_filter .sub_filters p {
    margin-top: 10px;
    font-size: 14px;
    color: gray;
    font-weight: 500;
}

.setting .setting_filter .sub_filters .contact_card {
    margin-top: 15px;
    box-shadow: 0 0 20px rgb(0, 0, 0, .1);
    border-radius: 10px;
    padding: 10px 20px;
}

.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
    background: #fff;
    padding-right: 3px;
}

.setting .select_card_filter {
    flex-wrap: wrap;
    justify-content: unset;
}

.setting .contact_head {
    width: 32%;
    margin: 0 0.66%;
    margin-top: 15px;
}

.setting .MuiFormControl-root {
    width: 100%;
}


.setting .contact_head label i {
    margin-right: 5px;
    padding: 7px 8px;
    background: #a01c1fbf;
    border-radius: 50%;
    color: #fff;
}

.setting .contact_head_ah {
    width: 100%;
}

.setting .setting_filter .sub_filters .contact_head h5 {
    margin: 20px 0;
}

.setting .setting_filter .sub_filters .contact_head p {
    margin: 20px 0;
}

.setting .setting_filter .sub_filters .contact_head .count span {
    font-weight: 600;
    font-size: 15px;
    margin: 0 5px;
    background: var(--color-main);
    color: #fff;
    padding: 3px 8px;
    border-radius: 8px;
    font-family: 'Poppins', sans-serif;
}

.setting .setting_contact .hide_delete {
    margin-top: 20px;
}

.setting .setting_contact .hide_delete label {
    margin-right: 30px;
    cursor: pointer;
    transition: .3s linear;
}

.setting .setting_contact .hide_delete label i {
    margin-left: 5px;
}

.setting .setting_contact .hide_delete label:hover {
    color: var(--color-main);
    font-weight: 600;
}

/* My Profile  */

.myprofile {
    min-height: 200px;
    padding-top: 125px !important;
}

.myprofile .head {
    background: var(--color-bl-2);
    display: flex;
    align-items: center;
    padding: 10px 20px;
}

.myprofile .head h1 {
    font-size: 22px;
    display: flex;
    align-items: center;
    font-weight: 900;
    color: rgb(77, 77, 77);
}

.myprofile .head span {
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    margin-left: 5px;
    font-weight: 600;
    color: rgb(77, 77, 77);
}

.myprofile .basic_profile {
    margin-top: 15px;
    align-items: flex-start;
}

.myprofile .basic_profile aside,
.right_side {
    width: calc(100% - 200px);
    height: 260px;
    position: relative;
    padding-left: 20px;
}

.myprofile .basic_profile aside {
    width: 200px;
    height: 205px;
    padding-left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    border-radius: 10px;
    box-shadow: 0 0 20px rgb(0, 0, 0, .1);
}

.myprofile .basic_profile aside input {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
}

.myprofile .basic_profile aside img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    aspect-ratio: 16/12;
}

.color_text {
    color: var(--color-main);
}

.color_text button {
    border: none;
    outline: none;
    background: none;
    color: skyblue;
    cursor: pointer;
    transition: .3s linear;
}

.color_text button:hover {
    color: var(--color-bl);
}

.border_text {
    border-bottom: 1px solid rgb(224, 224, 224);
    padding-bottom: 5px;
}

.myprofile .basic_profile .right_side .top_basic {
    border-bottom: 2px solid rgb(101, 101, 101);
    padding-bottom: 10px;
    position: relative;
}

.myprofile .basic_profile .right_side .top_basic::before {
    position: absolute;
    content: '';
    top: 0;
    left: 49%;
    width: 3px;
    height: 70%;
    background: rgb(101, 101, 101);
}

.myprofile .basic_profile .right_side .top_basic .left_b,
.right_b {
    position: relative;
    padding-left: 20px;
}


.myprofile .basic_profile .right_side .top_basic .card {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.myprofile .basic_profile .right_side .top_basic .card label {
    margin-right: 5px;
    font-size: 14px;
    font-weight: 500;
}

.myprofile .basic_profile .right_side .top_basic .card p {
    margin-right: 5px;
    font-size: 14px;
    font-weight: 600;
}


.myprofile .basic_profile .right_side .bottom_basic h3 {
    margin: 10px 10px 10px 15px;
}

.myprofile .basic_profile .right_side .bottom_basic .cards {
    flex-wrap: wrap;
}

.myprofile .basic_profile .right_side .bottom_basic .cards li {
    width: 30%;
    margin-top: 8px;
    font-size: 14px;
    font-weight: 500;
    color: rgb(34, 156, 204);
    transition: .3s linear;
    cursor: pointer;
}

.myprofile .basic_profile .right_side .bottom_basic .cards li::marker {
    color: var(--color-main);
}

.myprofile .about_yourself .nomal_det p {
    margin-top: 8px;
    font-size: 13px;
    font-weight: 500;
}

.myprofile .about_yourself .det_box {
    position: relative;
    margin-top: 15px;
    border-bottom: 1px solid rgb(224, 224, 224);
}


.myprofile .about_yourself .det_box .cards {
    margin-top: 15px;
    height: auto;
    position: relative;
}

.myprofile .about_yourself .det_box .cards::before {
    position: absolute;
    content: '';
    top: 2%;
    left: 51%;
    width: 2px;
    height: 90%;
    background: rgb(216, 216, 216);
}

.myprofile .about_yourself .det_box .cards .left_b,
.right_b {
    width: 50%;
}

.myprofile .about_yourself .det_box .cards .card {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

.myprofile .about_yourself .det_box .cards .card label {
    margin-right: 5px;
    font-size: 14px;
    font-weight: 500;
    width: 200px;
    color: rgb(167, 167, 167);
}

.myprofile .about_yourself .det_box .cards .card p {
    margin-right: 5px;
    font-size: 14px;
    font-weight: 600;
    width: 200px;
    word-break: break-all;
}

.myprofile .about_yourself .det_box .small_cards {
    padding: 20px 0;
    justify-content: unset;
}

.myprofile .about_yourself .det_box .small_cards li {
    list-style-type: none;
    border: 1px solid rgb(199, 199, 199);
    padding: 7px 13px;
    border-radius: 20px;
    font-weight: 600;
    font-size: 14px;
    margin-right: 15px;
}


/* New Matches */

.new_matches {
    min-height: 200px;
    padding-top: 125px !important;
    align-items: unset;
}

.new_matches .match_left_bx,
.match_right_bx {
    width: 300px;
    position: relative;
    overflow-y: auto;
}

.new_matches .match_left_bx,
.match_right_bx {
    width: 220px;
    min-height: 300px;
    position: relative;
}

.new_matches .match_right_bx {
    width: calc(100% - 265px);
    padding: 15px;
}

.new_matches .match_right_bx .card_matches {
    height: 200px;
    box-shadow: 0 0 20px rgb(0, 0, 0, .03);
    border-radius: 10px;
    padding: 15px 10px;
    margin-bottom: 10px;
    background: #fff;
    position: relative;
}

.new_matches .match_right_bx .card_matches .img_bx {
    width: 135px;
    height: 100%;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.new_matches .match_right_bx .card_matches .img_bx img {
    width: 100%;
    max-height: 100%;
}

.new_matches .match_right_bx .card_matches .content_bx {
    height: 100%;
    width: calc(100% - 300px);
    border-right: 1px solid rgb(235, 235, 235);
    padding-right: 15px;
}

.new_matches .match_right_bx .card_matches .connect_bx {
    height: 100%;
    width: 140px;
    /* border: 1px solid #000; */
}

.new_matches .match_right_bx .card_matches .content_bx h4 {
    color: rgb(60, 60, 60);
    font-weight: 600;
}

.new_matches .match_right_bx .card_matches .content_bx h4 i {
    font-size: 15px;
    margin-left: 5px;
}

.new_matches .match_right_bx .card_matches .content_bx .cont_head {
    margin-top: 5px;
    border-bottom: 1px solid rgb(235, 235, 235);
    padding-bottom: 10px;
}

.new_matches .match_right_bx .card_matches .content_bx .cont_head h6 {
    color: rgb(136, 136, 136);
    font-weight: 500;
}

.new_matches .match_right_bx .card_matches .content_bx .cont_head i {
    color: rgb(60, 200, 255);
    margin-right: 5px;
}

.new_matches .match_right_bx .card_matches .content_bx .cont_head span {
    font-size: 12px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
}

.new_matches .match_right_bx .card_matches .content_bx .cont_head .r_fav {
    font-size: 12px;
    font-weight: 600;
    position: relative;
}

.new_matches .match_right_bx .card_matches .content_bx .cont_head .r_fav {
    font-size: 12px;
    font-weight: 600;
    position: relative;
}

.new_matches .match_right_bx .card_matches .content_bx .cont_head .r_fav i {
    font-size: 23px;
    top: -20px;
    right: 00px;
    position: absolute;
    color: #f6aab2;
    cursor: pointer;
    transition: .2s linear;
    text-shadow: 0 5px 10px rgba(209, 134, 137, 1);
    animation: heart 2.5s linear infinite;
}

.added_to_fav_class {
    color: #a01c22;
}

.new_matches .match_right_bx .card_matches .content_bx .cont_head .r_fav i:hover {
    color: #a01c22;
}

@keyframes heart {
    0% {
        font-size: 23px;
    }

    50% {
        font-size: 25px;
        top: -23px;
    }

    100% {
        font-size: 23px;
    }
}

.new_matches .match_right_bx .card_matches .content_bx ul {
    list-style-type: none;
    flex-wrap: wrap;
}

.new_matches .match_right_bx .card_matches .content_bx ul li {
    width: 40%;
    font-size: 13px;
    margin-top: 8px;
    color: rgb(136, 136, 136);
}

.new_matches .match_right_bx .card_matches .content_bx p {
    font-size: 12px;
    margin-top: 15px;
    color: rgb(136, 136, 136);
    word-break: break-all;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    max-height: 3em;
    line-height: 1.5em;
}

.new_matches .match_right_bx .card_matches .content_bx p a {
    color: skyblue;
    font-size: 12px;
    font-weight: 600;
}

.new_matches .match_right_bx .card_matches .connect_profile {
    flex-direction: column;
    height: 100%;
}

.new_matches .match_right_bx .card_matches .connect_profile i {
    font-size: 40px;
    margin: 10px 0;
    border: 2px solid var(--color-main);
    color: var(--color-main);
    padding: 1px 4px;
    border-radius: 50%;
    cursor: pointer;
    transition: .3s linear;
}

.new_matches .match_right_bx .card_matches .connect_profile i:hover {
    transform: scale(1.05);
}

.new_matches .match_right_bx .card_matches .connect_profile p {
    font-size: 11px;
    font-weight: 500;
}

.new_matches .match_right_bx .card_matches .connected_profile {
    flex-direction: column;
}

.new_matches .match_right_bx .card_matches .connected_profile p {
    font-size: 14px;
    font-weight: 500;
    margin-top: 6px;
    text-align: center;
}

.new_matches .match_right_bx .card_matches .connected_profile button {
    width: 80%;
    margin-top: 10px;
    padding: 8px 0;
    border: 1px solid var(--color-main);
    border-radius: 20px;
    background: var(--color-main);
    color: #fff;
    cursor: pointer;
    transition: .3s linear;
}

.new_matches .match_right_bx .card_matches .connected_profile button i {
    margin-right: 5px;
}

.new_matches .match_right_bx .card_matches .connected_profile button:hover {
    background: transparent;
    color: var(--color-main);
}

.new_matches .match_left_bx .filter {
    width: 100%;
    background: rgb(231, 231, 231);
    padding: 10px;
    border-radius: 10px;
}

.new_matches .match_left_bx .filter h3 {
    width: 100%;
    background: rgb(180 180 180);
    padding: 5px;
    color: var(--color-wh);
    font-size: 17px;
}

.new_matches .match_left_bx .filter h3 i {
    margin-right: 5px;
}

.new_matches .match_left_bx .filter .filter_card {
    width: 100%;
}

.new_matches .match_left_bx .filter .filter_card .filter_head {
    width: 100%;
    background: rgb(253, 247, 247);
    padding: 8px;
}

.new_matches .match_left_bx .filter .filter_card .filter_head i {
    all: unset;
    cursor: pointer;
    transition: .3s linear;
}

.new_matches .match_left_bx .filter .filter_card .filter_head i {
    all: unset;
    cursor: pointer;
}

.new_matches .match_left_bx .filter .filter_card .radio_section {
    width: 100%;
    background: var(--color-wh);
}

.new_matches .match_left_bx .filter .filter_card .radio_section {
    width: 100%;
    background: var(--color-wh);
    padding: 10px;
    max-height: 170px;
    overflow: hidden;
}

.new_matches .match_left_bx .filter .filter_card .radio_section .radio_card {
    all: unset;
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 15px;
}

.new_matches .match_left_bx .filter .filter_card .radio_section .radio_card label {
    all: unset;
    font-size: 12px;
    font-weight: 600;
    word-break: break-all;
    color: var(--color-bl);
}

.new_matches .match_left_bx .filter .filter_card .radio_section .radio_card input {
    width: unset;
    padding: 0;
    margin: 0;
    margin-right: 5px;
}

/* Basic Search Start  */

.basic_search {
    min-height: 200px;
    padding-top: 125px !important;
    align-items: unset;
}

.basic_search .basic_search_left_bx,
.basic_search_right_bx {
    width: calc(100% - 300px);
    position: relative;
}

.basic_search .basic_search_right_bx {
    width: 300px;
}

.basic_search .basic_search_left_bx .basic_search_card {
    display: flex;
    align-items: center;
}

.basic_search .basic_search_left_bx .basic_search_card label {
    font-size: 14px;
    font-weight: 600;
    line-height: 33px;
    margin-right: 20px;
    width: 150px;
}

.basic_search .basic_search_left_bx .basic_search_card {
    margin-bottom: 20px;
}

.basic_search .basic_search_left_bx .basic_search_card .inputs input {
    padding: 8px;
    border: 1px solid #b9b6b6;
    border-radius: 3px;
    width: 90px;
}

.basic_search .basic_search_left_bx .basic_search_card .inputs span {
    margin: 0 10px !important;
    color: #000 !important;
    font-weight: 600 !important;
    position: relative;
    top: 2px !important;
}

.basic_search .basic_search_left_bx .basic_search_card .inputs span {
    display: none;
}

.basic_search .basic_search_left_bx .basic_search_card .basic_section {
    align-items: center;
}

.basic_search .basic_search_left_bx .basic_search_card .basic_section .filter_card {
    width: 150px;
    z-index: unset;
}

.basic_search .basic_search_left_bx .basic_search_card .basic_section .filter_card input {
    background: #fff;
    border: 1px solid #c5c3c3;
}

.basic_search .basic_search_left_bx .basic_search_card .basic_section .filter_card i {
    top: 20px;
}

.basic_search .basic_search_left_bx .basic_search_card .basic_section span {
    margin: 0 10px;
    position: relative;
    top: 5px;
    font-weight: 600;
    color: #000;
    font-size: 13px;
}

.basic_search .basic_search_left_bx .basic_search_card .radio_btns .card {
    align-items: center;
}

.basic_search .basic_search_left_bx .basic_search_card .radio_btns .card input {
    accent-color: var(--color-main);
}

.basic_search .basic_search_left_bx .basic_search_card .radio_btns .card label {
    margin-left: 5px;
    position: relative;
    top: -2px;
}

.basic_search .basic_search_left_bx .basic_search_card button {
    background: var(--color-main);
    color: var(--color-wh);
    margin: 0 10px;
}

.basic_search .basic_search_left_bx .basic_search_card button:hover {
    background: var(--color-main-transparent);
}

.basic_search .basic_search_left_bx .basic_search_card .reset {
    background: none;
    color: var(--color-bl);
}

.basic_search .basic_search_left_bx .basic_search_card .reset:hover {
    color: var(--color-main);
    background: transparent;
}

.basic_search .basic_search_right_bx .filter {
    width: 100%;
    background: rgb(231, 231, 231);
    padding: 10px;
    border-radius: 10px;
}

.basic_search .basic_search_right_bx .filter h3 {
    width: 100%;
    background: rgb(180 180 180);
    padding: 5px;
    color: var(--color-wh);
    font-size: 17px;
}

.basic_search .basic_search_right_bx .filter .filter_card {
    width: 100%;
    position: relative;
    z-index: 999;
}

.basic_search .basic_search_right_bx .filter .filter_card .filter_head input {
    width: calc(100% - 60px);
}

.basic_search .basic_search_right_bx .filter .filter_card button {
    background: var(--color-wh);
    color: var(--color-bl);
    padding: 10px 15px !important;
    top: 7px;
    border: none;
    border-radius: 10px;
    font-weight: 600;
    cursor: pointer;
}

.basic_search .basic_search_right_bx .profile {
    width: 100%;
    background: var(--color-wh);
    border-radius: 10px;
    box-shadow: 0 0 20px var(--color-bl-3);
    padding: 10px 20px;
    margin-top: 20px;
}

.basic_search .head_band {
    background: var(--color-bl-2);
    padding: 10px 30px;
    margin-top: 40px;
    margin-bottom: 20px;
}





.inbox .title {
    margin-bottom: 30px;
}

#toast-container {
    z-index: 99999;
    top: 120px;
}


/* .inbox .match_right_bx .card_matches {
    height: ;
} */
.inbox .match_right_bx .card_matches .upgrade_bx_card {
    width: 100%;
    border: 1px solid var(--color-bl-2);
    margin: 10px auto;
    position: relative;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.inbox .match_right_bx .card_matches .upgrade_bx_card .bi-envelope-fill {
    position: absolute;
    top: -13px;
    left: 48%;
    background: var(--color-main);
    padding: 5px 7px;
    color: var(--color-wh);
    border-radius: 50%;
    font-size: 12px;
}

.inbox .match_right_bx .card_matches .upgrade_bx_card h6 {
    text-align: center;
    margin-top: 3px;
}

.inbox .match_right_bx .card_matches .upgrade_bx_card a {
    color: var(--color-main);
    margin-top: 5px;
    font-size: 13px;
    font-weight: 600;
}

.inbox_recevied .match_right_bx .card_matches .connect_profile i {
    padding: 3px 5px 1px 5px;
    font-size: 30px;
}

.editAge input {

    -webkit-box-flex-wrap: wrap;

    min-height: 38px;
    outline: 0 !important;

    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;
    margin-right: 5px;
    font-size: 15px;
    padding: 10px;
    width: 100%;
}

.noraml_in input {
    width: 68%;
    margin-right: 5px;
    min-height: 38px;
    font-size: 15px;
    outline: 0 !important;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;
    padding: 10px;
}

.heightCard input {
    -webkit-box-flex-wrap: wrap;
    min-height: 38px;
    outline: 0 !important;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;
    font-size: 15px;
}

.heightCard {
    width: 68%;
    margin-right: 5px;
}

#partnerP_edit .heightCard {
    width: 32%;
    margin-right: 16px;
}

#partnerP_edit .left_b .card:nth-child(1) .inputs {
    justify-content: flex-end !important;
    padding-right: 10px;
}

#partnerP_edit .left_b .card:nth-child(1) .inputs input {
    margin-left: 12px;
    width: 41.5%;
}

.eduction_bx_par .multipleSelect {
    width: 68%;
    margin-right: 15px;
}

#userAbout {
    width: 100%;
    font-size: 16px;
    padding: 5px;
}

#partner_preference_edit, #user_edit {
    background-color: #a01c22;
    color: #fff;
    border: none;
    padding: 5px;
    cursor: pointer;
    border-radius: 5px;
    margin-left: auto;
}


#partner_preference_update, #partnerLoad, #user_update, #userLoad {
    background-color: #1ca048;
    color: #fff;
    border: none;
    padding: 5px;
    cursor: pointer;
    border-radius: 5px;
    margin-left: auto;
    display: none;
}

#setting_update {
    background-color: #1ca048;
    color: #fff;
    border: none;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
    margin-left: auto;
}

#settingLoad {
    background-color: #1ca048;
    color: #fff;
    border: none;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
    margin-left: auto;
    display: none;
}

#settingUpdateDiv {
    text-align: center;
}

#react-select-15-listbox {
    max-height: 176px;
}

#react-select-16-listbox {
    max-height: 150px;
}

#react-select-17-listbox {
    max-height: 110px;
}


#editPref {
    background-color: #a01c22;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px;
    font-size: 16px;
    z-index: 99999;
    position: absolute;
    left: 94%;
    margin-top: 3px;
    cursor: pointer;
}



/* User Details Start */

.user_details {
    padding-bottom: 0;
}

.user_details .match_right_bx {
    width: 100%;
}

.user_details .match_right_bx .r_fav span {
    color: rgb(136, 136, 136);
    font-weight: 500 !important;
    font-size: 12px !important;
}

.user_details .match_right_bx .img_bx {
    position: relative;
    top: -50px;
    left: 0;
}

.user_details .match_right_bx .card_matches .content_bx .cont_head h6 {
    color: var(--color-main);
}

.user_details .match_right_bx .card_matches .img_bx {
    width: 190px !important;
    height: 132% !important;
    margin-right: 15px;
    box-shadow: 0 0 20px rgb(0, 0, 0, .1);
}

.user_details .match_right_bx .img_bx {
    position: relative;
    top: -2px;
    left: -10px;
    background: var(--color-wh);
}

.user_details .match_left_bx, .match_right_bx {
    min-height: 210px;
}


.deatils_user_bx {
    position: relative;
    align-items: unset;
}

.deatils_user_bx .left_bx, .right_bx {
    width: calc(100% - 260px);
    /* border: 1px solid #000; */

}

.deatils_user_bx .left_bx {
    width: 240px;
}

.deatils_user_bx .left_bx .ad_p {
    width: inherit();
    padding: 10px;
    border-radius: 10px;
    background: #fff;
    float: right;
}

.deatils_user_bx .left_bx .ad_p img {
    width: 230px;
    height: fit-content;
    border-radius: 10px;
}

.deatils_user_bx .left_bx h4 {
    color: gray;
    margin: 10px 0;
}

.deatils_user_bx .left_bx .randomProfiles {
    width: 230px;
}

.deatils_user_bx .left_bx .randomProfiles .rmProfile {
    width: 230px;
    background: #fff;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0 0 20px rgb(0, 0, 0, .05);
    margin-bottom: 10px;
    display: flex;
    text-decoration: unset;
}

.deatils_user_bx .left_bx .randomProfiles .rmProfile img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    box-shadow: 0 0 20px rgb(0, 0, 0, .05);
    margin-right: 10px;
}

.deatils_user_bx .left_bx .randomProfiles .rmProfile .content {
    width: calc(100% - 40px);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.deatils_user_bx .left_bx .randomProfiles .rmProfile .content h5 {
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #141414;
}

.deatils_user_bx .left_bx .randomProfiles .rmProfile .content h5:nth-child(2) {
    font-size: 11px;
    color: rgb(91 91 91);
    margin-top: 5px;
    font-weight: 600;
}

.deatils_user_bx .right_bx {
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
}

.deatils_user_bx .right_bx .id_btns button {
    margin-right: 10px;
    padding: 5px 10px;
    border-radius: 5px;
    border: none;
    outline: none;
}

.deatils_user_bx .right_bx h3 {
    font-size: 15px;
    font-weight: 600;
    color: var(--color-main);
    margin: 20px 0;
    display: flex;
    align-items: center;
}

.deatils_user_bx .right_bx h3 img {
    width: 35px;
    margin-right: 10px;
}

.deatils_user_bx .right_bx p {
    font-size: 12px;
    font-weight: 600;
}

.deatils_user_bx .right_bx li {
    list-style: none;
    font-size: 13px;
    font-weight: 600;
    margin-top: 15px;
    display: flex;
    align-items: center;
    margin-left: 40px;
}

.deatils_user_bx .right_bx li img {
    width: 25px;
    margin-right: 10px;
}

.deatils_user_bx .right_bx .contact_det {
    padding: 20px;
    border: 1px solid rgb(222, 222, 222);
    width: 280px;
    position: relative;
}

.deatils_user_bx .right_bx .contact_det .contact_upgrade {
    position: absolute;
    left: calc(100%);
    top: 45%;
    text-wrap: nowrap;
}

.deatils_user_bx .right_bx .contact_det .contact_upgrade a {
    margin-right: 5px;
    transition: .3s linear;
}

.deatils_user_bx .right_bx .contact_det .contact_upgrade a:hover {
    color: #000;
}

/* .deatils_user_bx .right_bx .contact_det::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(184, 184, 184, .1);
    z-index: 99;
    backdrop-filter: blur(5px);
    border-radius: 10px;
} */

.deatils_user_bx .right_bx .contact_det_active::before {
    width: 0;
    height: 0;
}

.deatils_user_bx .right_bx .contact_det h4:nth-child(1) {
    margin-bottom: 15px;
}

.deatils_user_bx .right_bx .contact_det .mail_icon {
    position: absolute;
    top: 38%;
    right: -13px;
    font-size: 15px;
    z-index: 99999999;
    background: #fff;
    color: var(--color-main);
    border: 1px solid rgb(223, 223, 223);
    border-radius: 50%;
    padding: 3px 5px;
}

.deatils_user_bx .right_bx .contact_det_active .mail_icon {
    display: none;
}

.deatils_user_bx .right_bx .contact_det label {
    font-size: 13px;
    font-weight: 600;
}

.deatils_user_bx .right_bx .contact_det label i {
    margin-right: 5px;
    color: #1ca048;
}

.deatils_user_bx .right_bx .contact_det h4:nth-child(2) {
    margin-bottom: 10px;
}

.deatils_user_bx .right_bx .contact_det label:nth-child(3) i {
    margin-right: 5px;
    color: #ffa400;
}

.deatils_user_bx .right_bx .contact_det h4 {
    margin-left: 25px;
    font-size: 13px;
    font-weight: 600;
}

.deatils_user_bx .partner_pre_user_bx {
    margin-top: 5px;
    border-top: 1px solid #000;
    padding-top: 30px;
}

.deatils_user_bx .partner_pre_user_bx .both_user {
    position: relative;
}

.deatils_user_bx .partner_pre_user_bx .both_user .user_card {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
}

.deatils_user_bx .partner_pre_user_bx .both_user .user_card img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.deatils_user_bx .partner_pre_user_bx .both_user .user_card h4 {
    margin-top: 5px;
    font-size: 13px;
    text-wrap: nowrap;
}

.deatils_user_bx .partner_pre_user_bx .both_user .user_card h6 {
    position: relative;
    padding: 3px 8px;
    border: 1px solid #000;
    top: -13px;
}

.deatils_user_bx .partner_pre_user_bx .both_user .user_card h6::before {
    content: '';
    position: absolute;
    width: 50px;
    height: 2px;
    background: #000;
    top: 45%;
    left: -50px;
}

.deatils_user_bx .partner_pre_user_bx .both_user .user_card h6::after {
    content: '';
    position: absolute;
    width: 50px;
    height: 2px;
    background: #000;
    top: 45%;
    right: -50px;
}

.deatils_user_bx .partner_pre_user_bx .pp_details {
    position: relative;
    margin-top: 25px;
    padding-bottom: 50px;
}

.deatils_user_bx .partner_pre_user_bx .pp_details .pp_card {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    padding: 10px 0;
    border-bottom: 1px solid rgb(236, 236, 236);
}

.deatils_user_bx .partner_pre_user_bx .pp_details .pp_card .bi-check-circle-fill {
    color: green;
}

.deatils_user_bx .partner_pre_user_bx .pp_details .pp_card .bi-x-circle-fill {
    color: red;
}

.deatils_user_bx .partner_pre_user_bx .pp_details .pp_card .labels h5 {
    color: var(--color-main);
}

.deatils_user_bx .partner_pre_user_bx .pp_details .pp_card .labels h6 {
    margin-top: 3px;
}

.deatils_user_bx .hide_pp_details .both_user .user_card12::before {
    position: absolute;
    content: '';
    width: calc(100% + 30px);
    top: -20px;
    left: -20px;
    height: calc(100% + 40px);
    background: rgb(184, 184, 184, .01);
    backdrop-filter: blur(5px);
    z-index: 999;
}

.deatils_user_bx .hide_pp_details .pp_details::before {
    position: absolute;
    content: '';
    width: calc(100% + 30px);
    top: -20px;
    left: -20px;
    height: 100%;
    background: rgb(184, 184, 184, .01);
    backdrop-filter: blur(5px);
    z-index: 999;
}

.active_nav_links {
    color: var(--color-main) !important;
}

.radio_sec_active {
    max-height: unset !important;
}


.contactus {
    position: relative;
    align-items: start;
    flex-wrap: wrap;
}

.contactus .card_bx {
    display: flex;
    min-width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
}

.contactus .card_bx label {
    margin-top: 20px;
    font-size: 15px;
    font-weight: 600;
    width: 90%;
}

.contactus .card_bx label i {
    margin-right: 8px;
}

.contactus .card_bx input, textarea {
    width: 90%;
    margin-top: 10px;
    padding: 13px 15px;
    border-radius: 10px;
    border: 1px solid rgb(165, 165, 165);
    outline: none;
    position: relative;
    z-index: 99;
    cursor: pointer;
}


.contactus .left_job_bx {
    padding-top: 25px;
}

.contactus .left_job_bx p {
    margin-top: 10px;
    font-size: 13px;
}

.contactus .left_job_bx h3 {
    margin-top: 10px;
}

.contactus .left_job_bx ul {
    margin-top: 10px;
}

.contactus .left_job_bx ul li {
    font-size: 13px;
    padding-top: 10px;
}

.contactus .filter_card {
    width: 90%;
    margin: 20px auto;
}

.contactus .filter_card label {
    margin-top: 20px;
    font-size: 15px;
    font-weight: 600;
    width: 90%;
    color: #000;
}

.contactus .filter_card label i {
    position: unset;
    right: unset;
    top: unset;
    margin-right: 5px;
}

.contactus .filter_card input {
    padding: 13px 15px;
    border: 1px solid rgb(165, 165, 165);
    border-radius: 10px;
}

.contactus button {
    margin-top: 10px;
    padding: 10px 5px;
    width: 100px;
    border-radius: 10px;
    background: var(--color-main);
    color: #fff;
    border: 2px solid var(--color-main);
    outline: none;
    cursor: pointer;
    transition: .3s linear;
}

.contactus button:hover {
    background: transparent;
    color: var(--color-main);
}

.contactus .head {
    width: 100%;
    text-align: center;
    font-size: 25px;
    margin-top: 50px;
    padding-bottom: 30px;
}

.err {
    color: red;
    text-align: left;
    font-size: 14px;
    font-weight: 600;
    text-align: left;
    width: 90%;
}

.nri_page::before {
    background: url('../img/nri_img.jpg')no-repeat 0 12%/cover;
}


.nri_page .content h1 {
    color: var(--color-wh);
    background: #a01c2270;
    border-radius: 10px;
    padding: 8px 30px;
    font-size: 50px;
}


/* Pricing Start  */

.pricing {
    position: relative;
    width: 100%;
    height: auto;
    /* border: 1px solid #000; */
    margin: auto;
    margin-top: 60px;
    padding: 30px;
}

.pricing .head {
    width: 100%;
    height: 20%;
    /* border: 1px solid #000; */
    border: none;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.pricing .head h6 {
    color: var(--color-main);
    font-size: 12px;
}

.pricing .head .spans {
    display: flex;
    align-items: center;
}

.pricing .head .spans span {
    width: 30px;
    height: 2.5px;
    background: var(--color-main);
}

.pricing .head .spans span:nth-child(2) {
    width: 5px;
    margin-left: 5px;
}

.pricing .head .spans span:nth-child(3) {
    width: 5px;
    margin-left: 5px;
}

.pricing .cards {
    width: 100%;
    height: auto;
    /* border: 1px solid #000; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 30px;
}

.pricing .cards .card {
    position: relative;
    width: fit-content;
    height: 285px;
    background: #fff;
    border-radius: 10px;
    margin: 10px;
    padding: 20px;
    cursor: pointer;
    box-shadow: 0px 0px 8px gray;
    transition: .3s linear;
    overflow: hidden;
}

.pricing .cards .card h2 {
    color: var(--color-main);
    font-family: 'Poppins', sans-serif;
}

.pricing .cards .card h2 span {
    font-size: 12px;
}

.pricing .cards .card p {
    font-size: 11px;
}

.pricing .cards .card ul {
    list-style: none;
    margin-top: 20px;
}

.pricing .cards .card ul li {
    padding: 5px 0px;
    font-size: 12px;
    color: rgb(0, 0, 0, .7);
    font-weight: 600;
}

.pricing .cards .card ul li span {
    font-family: 'Poppins', sans-serif;
    margin-right: 5px;
}

.pricing .cards .card ul li .span_x {
    color: red;
}

.pricing .cards .card ul li .span_yes {
    color: green;
}

.pricing .cards .card ul li .bi {
    font-size: 13px;
}

.pricing .cards .card button {
    background: transparent;
    border: 2px solid rgb(130, 130, 130);
    color: rgb(130, 130, 130);
    padding: 5px 15px;
    border-radius: 20px;
    margin-top: 20px;
    margin-left: 15px;
    font-weight: 500;
    outline: none;
    cursor: pointer;
    text-wrap: nowrap;
    width: fit-content;
    transition: .3s linear;
}

.pricing .cards .card button a {
    color: var(--color-main);
}

.pricing .cards .card button:hover {
    background: var(--color-main);
    border: 2px solid var(--color-main);
    color: #fff;
}

.pricing .cards .card button:hover a {
    color: var(--color-wh);
}

.pricing .cards .card h6 {
    position: absolute;
    width: 130px;
    height: 25px;
    background: var(--color-main);
    top: 10px;
    right: -40px;
    transform: rotate(45deg);
    color: #fff;
    text-align: center;
    padding-top: 5px;
}

.pricing .cards .card:nth-child(1) h6 {
    display: none;
}

.pricing .cards .card:nth-child(2) h6 {
    display: none;
}

.pricing .cards .card:nth-child(3) h6 {
    display: none;
}

.paynow {
    width: 50%;
    height: 50%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.active_vip_nav::before {
    display: none;
}


.pra_detai p {
    margin-top: 25px;
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
}

.register_band3::before {
    position: absolute;
    content: '';
    width: 100px;
    height: 100%;
    left: unset;
    right: -50px;
    top: 0;
    background: url('../img/royal_design.png')no-repeat center center/cover;
    z-index: 999;
}

.about_card_sec .left_about_bx, .right_about_bx {
    position: relative;
    width: 50%;
    margin-top: 20px;
}

.about_card_sec .left_about_bx {
    padding-right: 20px;
}

.about_card_sec .left_about_bx img {
    width: 100%;
}

.about_card_sec .right_about_bx p {
    font-style: italic;
    margin-top: 30px;
    font-size: 14px;
    font-weight: 600;
}

.about_card_sec .right_about_bx p:nth-child(1) {
    margin-top: 20px;
}

.mainSectionProfile {
    background: #80808014;
    position: relative;
}

.clickToOpenImges {
    position: absolute;
    top: 10px;
    left: 10px;
    font-size: 20px;
    z-index: 9999;
    cursor: pointer;
    transition: .3s linear;
}

.crown {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    z-index: 9999;
    cursor: pointer;
    transition: .3s linear;
}

.crown img {
    width: 30px !important;
    border-radius: 10px !important;
}


.clickToOpenImges2 {
    top: -400%;
    right: 25px;
}

.mainSectionProfile .showUserImages {
    width: 300px;
    height: 280px;
    background: #fff;
    border-radius: 10px;
    position: absolute;
    z-index: 999;
    display: flex;
    align-items: center;
    top: 10%;
    left: 45%;
    border: 2px solid #fff;
    box-shadow: 0 0 20px rgb(0, 0, 0, .07);
    padding: 5px;
}

.mainSectionProfile .left_right_btn {
    width: 330px;
    border-radius: 10px;
    position: absolute;
    z-index: 99999;
    display: flex;
    align-items: center;
    top: 16.55%;
    left: calc(45% - 20px);
}

.mainSectionProfile .left_right_btn i {
    font-size: 26px;
    color: #757575;
    cursor: pointer;
    transition: .3s linear;
}

.mainSectionProfile .left_right_btn i:hover {
    color: #000;
}

.mainSectionProfile .showUserImages img {
    width: 100%;
    border-radius: 10px;
}

.imageShopPopup {
    display: none;
}

.imageShopPopup_active {
    display: unset;
}


.container_pri {
    margin: 30px auto;
    overflow: hidden;
    width: 380px;
}


.printer-top {
    z-index: 1;
    border: 6px solid #666666;
    height: 6px;
    border-bottom: 0;
    border-radius: 6px 6px 0 0;
    background: #333333;
}

.printer-bottom {
    z-index: 0;
    border: 6px solid #666666;
    height: 6px;
    border-top: 0;
    border-radius: 0 0 6px 6px;
    background: #333333;
}

.paper-container {
    position: relative;
    overflow: hidden;
    height: 510px;
}

.paper {
    background: #ffffff;
    height: 510px;
    position: absolute;
    z-index: 2;
    margin: 0 12px;
    margin-top: -12px;
    animation: print 1800ms cubic-bezier(0.68, -0.55, 0.265, 0.9);
    -moz-animation: print 1800ms cubic-bezier(0.68, -0.55, 0.265, 0.9);
    box-shadow: 0 0 20px rgb(0, 0, 0, .08);
}

.main-contents {
    margin: 0 12px;
    padding: 24px;
}

.jagged-edge {
    position: relative;
    height: 20px;
    width: 100%;
    margin-top: -1px;
}

.jagged-edge:after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    height: 20px;
    background: linear-gradient(45deg, transparent 33.333%, #ffffff 33.333%, #ffffff 66.667%, transparent 66.667%), linear-gradient(-45deg, transparent 33.333%, #ffffff 33.333%, #ffffff 66.667%, transparent 66.667%);
    background-size: 16px 40px;
    background-position: 0 -20px;
}

.success-icon {
    text-align: center;
    font-size: 48px;
    height: 72px;
    background: #359d00;
    border-radius: 50%;
    width: 72px;
    height: 72px;
    margin: 18px auto;
    color: #fff;
}

.success-title {
    font-size: 22px;
    text-align: center;
    color: #666;
    font-weight: bold;
    margin-bottom: 16px;
}

.success-description {
    font-size: 15px;
    line-height: 21px;
    color: #999;
    text-align: center;
    margin-bottom: 24px;
}

.order-details {
    text-align: center;
    color: #333;
    font-weight: bold;
}

.order-details .order-number-label {
    font-size: 18px;
    margin-bottom: 8px;
}

.order-details .order-number {
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    line-height: 48px;
    font-size: 18px;
    padding: 8px 0;
    margin-bottom: 24px;
}

.order-footer {
    text-align: center;
    line-height: 18px;
    font-size: 18px;
    margin-bottom: 8px;
    font-weight: bold;
    color: #999;
}

@keyframes print {
    0% {
        transform: translateY(-90%);
    }

    100% {
        transform: translateY(0%);
    }
}

@-webkit-keyframes print {
    0% {
        -webkit-transform: translateY(-90%);
    }

    100% {
        -webkit-transform: translateY(0%);
    }
}

@-moz-keyframes print {
    0% {
        -moz-transform: translateY(-90%);
    }

    100% {
        -moz-transform: translateY(0%);
    }
}

@-ms-keyframes print {
    0% {
        -ms-transform: translateY(-90%);
    }

    100% {
        -ms-transform: translateY(0%);
    }
}


.plan_contact_line {
    text-align: center;
    border: 1px solid gray;
    width: 52vw;
    margin: 35px auto 0 auto;
    padding: 9px 10px;
    font-size: 17px;
    font-weight: 600;
    border-radius: 20px;
}