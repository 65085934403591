.payment-summary {
    background-color: #f7f8fa;
    padding: 20px;
    max-width: 400px;
    margin: 20px auto;
    border-radius: 10px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
    font-family: 'Arial', sans-serif;
  }
  
  .head {
    color: #333;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .details {
    margin-top: 40px;
  }
  
  .detail-row {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    font-size: 18px;
  }
  
  .label {
    color: #555;
    font-weight: 600;
  }
  
  .value {
    color: #333;
  }
  
  .divider {
    border: none;
    border-top: 1px solid #ddd;
    margin: 15px 0;
  }
  
  .total {
    font-size: 20px;
    font-weight: bold;
    color: #000;
  }
  
  .pay-button {
    background-color: #28a745;
    color: white;
    padding: 10px 20px;
    font-size: 18px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .pay-button:hover {
    background-color: #218838;
  }
  