.fcs-display {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.fcc-display {
    display: flex;
    align-items: center;
    justify-content: center;
}

.fccf-display {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.fcsf-display {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
}

.fc-display {
    display: flex;
    align-items: center;
}

.btn_reset {
    border: none;
    outline: none;
    background: unset;
    font-family: "Roboto", sans-serif;
    font-weight: 600;
    cursor: pointer;
    border-radius: 5px;
    transition: .3s linear;
}

.n_padding {
    padding: 8px 14px;
}

.w100 {
    width: 100%;
}

.w95 {
    width: 95%;
}

.w90 {
    width: 90%;
}

.w80 {
    width: 80%;
}

.w75 {
    width: 75%;
}

.w70 {
    width: 70%;
}

.w65 {
    width: 65%;
}

.w60 {
    width: 60%;
}

.w55 {
    width: 55%;
}

.w50 {
    width: 50%;
}

.m_auto {
    margin: auto;
}

.head {
    font-size: var(--size_head);
}

.center {
    text-align: center;
}

.fw4 {
    font-weight: 400;
}

.fw5 {
    font-weight: 500;
}

.fw6 {
    font-weight: 600;
}

.fw7 {
    font-weight: 700;
}

.fw8 {
    font-weight: 800;
}

.fw9 {
    font-weight: 900;
}

a {
    text-decoration: none;
    transition: .3s linear;
}

.error_n {
    display: none;
}

.error_r {
    color: red;
    display: unset !important;
    font-size: 13px;
    margin-top: 5px;
}

.error_g {
    display: unset !important;
    color: green;
    font-size: 13px;
    margin-top: 5px;
}

.delete_icon {
    background: red !important;
    color: rgb(255, 255, 255) !important;
    padding: 5px 7px !important;
    position: absolute !important;
    top: 5px !important;
    right: 3px !important;
    border-radius: 20% !important;
    font-size: 16px !important;
    cursor: pointer !important;
}


.otpDashboard {
    width: 60px;
    padding: 6px 9px;
    border-radius: 10px;
    border: 2px solid #a01c22;
    font-size: 11px;
    color: #a01c22;
    font-weight: 700;
}

.tipicalInput {
    padding: 10px 10px;
    border: 1px solid #8080808c;
    border-radius: 4px;
    width: 70%;

}