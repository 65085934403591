@media screen and (max-width: 1250px) {
    header .search_bx {
        width: 85%;
    }

    .nri_tags_first {
        width: 90%;
    }

    .spiritual {
        width: 100%;
        height: unset;
    }
    .spiritual .sub_spiritual_bx {
        margin-top: -50px;
        width: 90%;
    }
    .spiritual .left_bx, .right_bx {
        align-items: unset !important;
    }

    .register_band {
        width: 90%;
    }

    .why {
        width: 90%;
    }

    .nri_rishtey {
        width: 90%;
    }

    .sub_footer {
        width: 90%;
    }
    .deatils_user_bx .left_bx, .right_bx {
        width: 100%;
    }
}


@media screen and (max-width: 850px) {
    header:before {
        background: url('../img/bg5.webp')no-repeat right 10%  center/cover;
    }

    .register_band button {
        text-wrap: nowrap;
    }

    header .content h1 {
        font-size: 50px;
    }

    header .content p {
        font-size: 16px;
    }
    .nri_rishtey {
        width: 90%;
    }

    .spiritual {
        flex-wrap: wrap;
    }

    .spiritual .left_bx,
    .right_bx {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .sub_footer {
        flex-wrap: wrap;
        justify-content: unset;
    }

    footer .foot_bx {
        width: 33% !important;
    }
    .register_band h1 {
        font-size: 15px;
    }
}


@media screen and (max-width: 720px) {
    header .search_bx {
        flex-wrap: wrap;
        padding: 15px 20px;
        height: auto;
    }

    header .search_bx .filter_card {
        width: 49% !important;
        margin-top: 10px;
    }

    header .search_bx .filter_card button {
        width: 100%;
        padding: 10px 15px;
    }

   

    .nri_rishtey .right_bx_ad h1 {
        font-size: 20px;
    }

    .nri_rishtey .left_bx_ad h1 {
        font-size: 24px;
    }
}


@media screen and (max-width: 680px) {
    .why .why_cards {
        flex-wrap: wrap;
    }
    .why .why_cards .why_card {
        width: 100%;
        margin-top: 20px;
    }
    .why .why_cards .why_card:nth-child(2) {
        border: none;
    }

    .register_band {
        padding: 0 10px 0 60px;
    }

    .register_band button {
        font-size: 13px;
    }
    .register_band h1 {
        margin-top: 25px;
    }
}

@media screen and (max-width: 541px) {
    .spiritual h4:nth-child(odd) {
        font-size: 15px;
    }
    .spiritual h4 {
        font-size: 13px;
    }
    .register_band {
        padding: 0 10px 0 30px;
    }
    header .content h1 {
        font-size: 40px;
    }
    header .content p {
        font-size: 14px;
        text-align: center;
    }
    
    .nri_tags ul {
        flex-wrap: wrap;
    }
    .nri_tags ul li {
        margin: 5px;
    }
    .nri_rishtey {
        height: auto;
    }
    .nri_rishtey .left_bx_ad {
        width: 100%;
        border-right: unset;
    }
    .nri_rishtey .left_bx_ad, .right_bx_ad {
        width: 100%;
        margin: 15px 0;
    }
    footer .foot_bx {
        width: 49% !important;
    }
    .nri_rishtey .left_bx_ad h1 {
        font-size: 18px;
    }
    .nri_rishtey .tags {
        flex-wrap: wrap;
    }
    .nri_rishtey .tags p {
        border: none;
    }
}
@media screen and (max-width: 400px) {
    header .content h1 {
        font-size: 30px;
    }
    footer .foot_bx {
        width: 100% !important;
        height: auto !important;
        margin-bottom: 15px;
    }
    header .search_bx {
        width: 95% !important;
        padding: 15px 10px;
    }
}